// @mui material components

import AboutUs from "layouts/pages/landing-pages/about-us";
import SignIn from "layouts/pages/authentication/sign-in";

const routes = [
  {
    name: "About Us",
    route: "/pages/landing-pages/about-us",
    component: <AboutUs />,
  },
  {
    name: "Sign In",
    route: "/pages/landing-pages/sign-in",
    component: <SignIn />,
  },
];

export default routes;
